import React, { PropsWithChildren } from 'react';
import { EmptyEventsProps } from './EmptySection.interface';
import { theme } from '@/constants/theme.constant';
import { Button } from '@/components/ui/button';
import { CardDescription, CardHeader, CardTitle } from '@/components/ui/card';

export const EmptySection: React.FC<PropsWithChildren<EmptyEventsProps>> = ({
  children,
  title,
  description,
  primaryButton,
  secondaryButton,
}) => (
  <>
    {children}

    {/* Title */}
    <div className="container lg:w-4/12 text-center relative z-10 my-auto mx-auto">
      <CardHeader className="text-center flex flex-col items-center">
        <CardTitle className="text-lg font-bold">{title}</CardTitle>
        {description && (
          <CardDescription className="max-w-80">{description}</CardDescription>
        )}
      </CardHeader>
      <div
        className={`mt-8 flex w-full justify-center ${theme.spacing.gap.main}`}
      >
        {secondaryButton && (
          <Button onClick={secondaryButton.onClick} variant="secondary">
            {secondaryButton.label}
          </Button>
        )}
        {primaryButton && (
          <Button onClick={primaryButton.onClick} variant="default">
            {primaryButton.label}
          </Button>
        )}
      </div>
    </div>
  </>
);
