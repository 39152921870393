import React from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const EmptySectionBackground = () => {
  const circles = new Array(5).fill(1);

  return (
    <div className="">
      {circles.map((_, idx) => (
        <Circle
          style={{
            height: `${(idx + 1) * 5}rem`,
            width: `${(idx + 1) * 5}rem`,
            border: `1px solid rgba(230, 230, 230, ${1 - (idx + 0.2) * 0.15})`,
          }}
          key={`motion-${idx}`}
          idx={idx}
        />
      ))}
    </div>
  );
};

export const Circle = ({ className, idx, ...rest }: any) => {
  return (
    <motion.div
      {...rest}
      initial={{
        opacity: 0,
        scale: 0.7,
        translateX: '-50%',
        translateY: '-50%',
      }}
      animate={{
        opacity: 1,
        scale: [1, 1.05, 1],
      }}
      transition={{
        delay: 0.3 + idx * 0.1,
        duration: 0.5,
        scale: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: 0.8,
          delay: 0.3 + idx * 0.1,
          repeatDelay: 2,
          ease: 'easeInOut',
        },
      }}
      className={twMerge(
        'absolute inset-0 left-1/2 top-1/2 transform rounded-full',
        className
      )}
    />
  );
};
