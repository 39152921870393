/* eslint-disable @typescript-eslint/no-redeclare */
export const MODALS = {
  EVENT: 'EVENT',
  PAYMENT: 'PAYMENT',
  MINISTRY_CREATE: 'MINISTRY-CREATE',
  MINISTRY_EDIT: 'MINISTRY-EDIT',
  MINISTRY_MEMBERS: 'MINISTRY-MEMBERS',
} as const;

export type MODALS = (typeof MODALS)[keyof typeof MODALS];
