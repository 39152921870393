import React, { FC } from 'react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Avatar, AvatarFallback } from '@/components/ui/avatar';

import { MinistryMemberRole } from '@/dtos/ministry/ministry.dto';
import { User } from 'lucide-react';

export const MinistryMemberIncognito: FC<{
  email: string;
  role: MinistryMemberRole;
}> = ({ email, role }) => {
  return (
    <div className="flex items-center justify-between gap-2 opacity-40">
      <div className="flex items-center gap-2">
        <Avatar className="h-8 w-8">
          <AvatarFallback>
            <User className="w-4 h-4" />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <span className="text-sm font-medium flex items-center gap-1">
            Convidado
            <span className="text-xs text-muted-foreground">(pendente)</span>
          </span>
          <span className="text-xs text-muted-foreground">{email}</span>
        </div>
      </div>
      <Select value={role} disabled>
        <SelectTrigger className="w-[90px] h-8 shadow-none border-none">
          <SelectValue defaultValue={role} />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="admin">Admin</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
