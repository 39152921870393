import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Dashboard } from '@/features/dashboard/Dashboard.component';
import { RootState } from '@/app/store';
import { AuthRoutes } from '@/features/auth/pages/AuthRoutes.component';
import { ToastContainer, Slide } from 'react-toastify';
import { useProfileSetupSideEffect, useCaptureSignup } from '@/hooks/auth.hook';
import { useSelector } from '@/hooks/redux.hook';
import { NavigateWithQuery } from './components/misc/NavigateWithQuery.component';
import { PrivateRoute } from './components/misc/PrivateRoute.component';
import { Toaster } from '@/components/ui/sonner';

const App: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.auth);

  useProfileSetupSideEffect();
  useCaptureSignup();

  return (
    <div className="font-sans">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <NavigateWithQuery to={profile ? '/dashboard' : '/login'} />
            }
          />

          <Route path="/*" element={<AuthRoutes />} />

          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>

      <Toaster position="bottom-center" richColors />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        hideProgressBar
        limit={3}
        icon={false}
        transition={Slide}
      />
    </div>
  );
};

export default App;
