import { useDispatch, useSelector } from 'react-redux';
import {
  openModal,
  closeModal,
  preventClose,
} from '@/components/modals/modals.slice';
import { MODALS } from './modals.constants';
import { peekLastModal } from './modals.utils';
import { RootState } from '@/app/store';

type ModalSettings = {
  closePrevented?: boolean;
};

export function useModalAPI<T>(name: MODALS, settings: ModalSettings = {}) {
  const dispatch = useDispatch();
  const { stack, states } = useSelector((state: RootState) => state.modals);

  if (settings.closePrevented) {
    dispatch(preventClose(name));
  }

  const open = (modalState?: T) => {
    dispatch(openModal({ name, modalState }));
  };

  const close = () => {
    dispatch(closeModal());
  };

  const state = states[name] as T;

  return {
    isOpen: peekLastModal(stack) === name,
    open,
    close,
    state,
  };
}
