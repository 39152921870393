import React, { FC } from 'react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

import { MinistryMemberRole } from '@/dtos/ministry/ministry.dto';
import { ProfileType } from '@/dtos/auth/profile.dto';

export const MinistryMemberUser: FC<{
  profile: ProfileType;
  currentProfile: ProfileType;
  role: MinistryMemberRole;
  handleRoleChange?: (id: string, role: MinistryMemberRole) => void;
}> = ({ profile, currentProfile, role }) => {
  return (
    <div key={profile.id} className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <Avatar className="h-8 w-8">
          <AvatarImage src={profile.picture} alt={profile.name} />
          <AvatarFallback color={profile.data.color}>
            {profile.name.slice(0, 2).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <span className="text-sm font-medium flex items-center gap-1">
            {profile.name}

            {profile.id === currentProfile.id && (
              <span className="text-xs text-muted-foreground">(você)</span>
            )}
          </span>
          <span className="text-xs text-muted-foreground">{profile.email}</span>
        </div>
      </div>
      <Select disabled value={role}>
        <SelectTrigger className="w-[90px] h-8 shadow-none border-none">
          <SelectValue defaultValue={role} />
        </SelectTrigger>

        <SelectContent>
          <SelectItem value="admin">Admin</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
