import React from 'react';
import { useDispatch, useSelector } from '@/hooks/redux.hook';
import { RootState } from '@/app/store';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import * as validations from '@/utils/validations.util';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LoadingStatus, SignInMethod } from '@/features/auth/auth.interface';
import { loginWithGoogle } from '@/features/auth/auth.slice';
import { GoogleLogo } from '../visual/GoogleLogo.component';
import supabaseAuth from '@/services/supabase';
import { ErrorMessage } from '../ui/error-message';

export const SignUpForm: React.FC<{ onSubmit: () => void }> = ({
  onSubmit,
}) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { status, signInMethod } = useSelector(
    (state: RootState) => state.auth
  );

  const validationSchema = Yup.object({
    name: validations.name,
    email: validations.email,
    password: validations.password,
  });

  const handleSubmit = async (values: {
    name: string;
    email: string;
    password: string;
  }) => {
    setLoading(true);
    await supabaseAuth.signUp(values.email, values.password, values.name);
    onSubmit();
  };

  const handleGoogleSignup = () => {
    dispatch(loginWithGoogle());
  };

  return (
    <Formik
      initialValues={{ name: '', email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={cn('flex flex-col gap-6 max-w-md mx-auto')} noValidate>
          <div className="flex flex-col items-center gap-2 text-center">
            <h1 className="text-2xl font-bold">Crie sua conta</h1>
          </div>

          <Button
            type="button"
            variant="outline"
            className="w-full flex items-center justify-center"
            onClickCapture={handleGoogleSignup}
            loading={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.GOOGLE
            }
            disabled={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.GOOGLE
            }
          >
            <GoogleLogo />
            Entrar com o Google
          </Button>

          {/* Separator */}
          <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:border-t after:border-border">
            <span className="relative z-10 bg-background px-2 text-muted-foreground">
              ou
            </span>
          </div>

          {/* Name Field */}
          <div className="grid gap-2">
            <Label htmlFor="name">Seu Nome</Label>
            <Field name="name">
              {({ field, meta }: any) => (
                <Input
                  {...field}
                  id="name"
                  type="name"
                  placeholder="Nome"
                  required
                  className={meta.touched && meta.error ? 'border-red-500' : ''}
                />
              )}
            </Field>
            <ErrorMessage name="name" />
          </div>

          {/* Email Field */}
          <div className="grid gap-2">
            <Label htmlFor="email">Seu Email</Label>
            <Field name="email">
              {({ field, meta }: any) => (
                <Input
                  {...field}
                  id="email"
                  type="email"
                  placeholder="nome@email.com"
                  required
                  className={meta.touched && meta.error ? 'border-red-500' : ''}
                />
              )}
            </Field>
            <ErrorMessage name="email" />
          </div>

          {/* Password Field */}
          <div className="grid gap-2">
            <Field name="password">
              {({ field, meta }: any) => (
                <Input
                  {...field}
                  id="password"
                  type="password"
                  placeholder="********"
                  required
                  className={meta.touched && meta.error ? 'border-red-500' : ''}
                />
              )}
            </Field>
            <ErrorMessage name="password" />
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full"
            disabled={isSubmitting || loading}
            loading={loading}
          >
            Criar minha conta
          </Button>

          {/* Footer */}
          <div className="text-center text-sm">
            Já tem uma conta?{' '}
            <a href="/" className="underline underline-offset-4">
              Entre aqui
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};
