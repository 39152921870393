import React from 'react';
import { AuthHero } from '../components/AuthHero.component';
import { BlurFade } from '@/components/visual/BlurFade.component';
import { LoginForm } from '@/components/auth/LoginForm.component';
import { Logo } from '@/components/visual/Logo.component';

export const Login: React.FC = () => {
  return (
    <div className="grid min-h-svh lg:grid-cols-2">
      <div className="relative hidden bg-muted lg:block">
        <AuthHero />
      </div>
      <div className="flex flex-col gap-4 p-4 md:p-10">
        <Logo />

        <div className="flex flex-1 items-center justify-center">
          <div className="w-full max-w-xs">
            <BlurFade isVisible delay={0.2}>
              <LoginForm />
            </BlurFade>
          </div>
        </div>
      </div>
    </div>
  );
};
