import { PopoverContent } from '@/components/ui/popover';
import { MinistryIcon } from '@/dtos/ministry/ministry.dto';
import { DynamicIcon } from 'lucide-react/dynamic';
import { FC } from 'react';
import { Button } from '../ui/button';
import { TooltipWithContent } from '../ui/tooltip';

const ICON_LABEL_MAP: Record<MinistryIcon, string> = {
  church: 'Igreja',
  home: 'Casa',
  'graduation-cap': 'Educação',
  'book-open': 'Bíblia',
  flame: 'Fogo',
  earth: 'Missão',
  heart: 'Misericórdia',
  wind: 'Vento',
  droplet: 'Chuva',
  guitar: 'Música',
  'mic-vocal': 'Música',
  drum: 'Música',
  clapperboard: 'Mídias',
  camera: 'Fotografia',
  'biceps-flexed': 'Serviço',
  'traffic-cone': 'Organização',
  coffee: 'Café',
  pizza: 'Comida',
  'monitor-smartphone': 'Tecnologia',
  megaphone: 'Comunicação',
};

export const IconSelect: FC<{
  onSelect: (icon: MinistryIcon) => void;
  selected: MinistryIcon;
}> = ({ onSelect, selected }) => {
  return (
    <PopoverContent className="p-0 w-72">
      <div
        className="p-2 grid grid-cols-4 gap-2"
        style={{ gridAutoRows: '1fr' }}
      >
        {Object.entries(ICON_LABEL_MAP).map(
          ([icon, label]: [MinistryIcon, string]) => (
            <TooltipWithContent content={label} key={icon}>
              <Button
                variant={selected === icon ? 'outline' : 'ghost'}
                onClick={() => onSelect(icon)}
                className="aspect-square flex items-center justify-center size-full"
              >
                <DynamicIcon name={icon} className="!size-5" />
              </Button>
            </TooltipWithContent>
          )
        )}
      </div>
    </PopoverContent>
  );
};
