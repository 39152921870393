import supabase from '@/services/supabase';
import { setUser, findOrCreateProfile } from '@/features/auth/auth.slice';
import { useDispatch, useSelector } from '@/hooks/redux.hook';
import { RootState } from '@/app/store';
import React from 'react';
import useTabVisibilityChange from './window.hook';

export const useCaptureSignup = () => {
  const [tabIsVisible, setTabIsVisible] = React.useState(true);
  const dispatch = useDispatch();

  useTabVisibilityChange({
    onActive: () => setTabIsVisible(true),
    onInactive: () => setTabIsVisible(false),
  });

  React.useEffect(() => {
    const { data: authListener } = supabase.onAuthStateChange(
      (event, session) => {
        if (event !== 'SIGNED_IN' || !tabIsVisible) return;

        dispatch(
          setUser({
            user: session.user,
            token: session.access_token,
          })
        );
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [dispatch, tabIsVisible]);
};

export const useProfileSetupSideEffect = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (user) {
      dispatch(findOrCreateProfile());
    }
  }, [dispatch, user]);
};
