import {
  MinistryMemberRole,
  MinistryType,
  MinistryMembersType,
  PopulatedMinistryMembersType,
} from '@/dtos/ministry/ministry.dto';
import api from './base.api';

export const ministryAPI = {
  getAll: () => api.get<MinistryType[]>('/ministry/me'),
  createOne: (data: Partial<MinistryType>) =>
    api.post<MinistryType>('/ministry', data),
  updateOne: (id: string, data: Partial<MinistryType>) =>
    api.patch<MinistryType>(`/ministry/${id}`, data),
  deleteOne: (id: string) => api.delete(`/ministry/${id}`),
  inviteMember: (
    id: string,
    member: { email: string; role: MinistryMemberRole }
  ) =>
    api.post<MinistryMembersType>(`/ministry/${id}/invite`, {
      member,
    }),
  getAllMembers: (id: string) =>
    api.get<PopulatedMinistryMembersType[]>(`/ministry/${id}/members`),
};
