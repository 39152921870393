// src/features/ministry/ministrySlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ministryAPI } from '../../api/ministry.api';
import { toast } from 'sonner';

import {
  LOADING_STATES,
  LoadingState,
} from '@/constants/loading-states.constant';
import { MinistryType } from '@/dtos/ministry/ministry.dto';

interface MinistryState {
  list: MinistryType[];
  current: string | null;
  status: LoadingState;
  error: string | null;
}

export const initialState: MinistryState = {
  list: [],
  current: null,
  status: LOADING_STATES.IDLE,
  error: null,
};

export const fetchMinistries = createAsyncThunk(
  'ministry/fetchMinistries',
  async () => {
    const response = await ministryAPI.getAll();

    return response.data;
  }
);

export const createMinistry = createAsyncThunk(
  'ministry/createMinistry',
  async (ministry: Partial<MinistryType>) => {
    const response = await ministryAPI.createOne(ministry);

    return response.data;
  }
);

export const updateMinistry = createAsyncThunk(
  'ministry/updateMinistry',
  async (
    { id, ministry }: { id: string; ministry: Partial<MinistryType> },
    { dispatch }
  ) => {
    const response = await ministryAPI.updateOne(id, ministry);

    dispatch(fetchMinistries());

    return response.data;
  }
);

export const deleteMinistry = createAsyncThunk(
  'ministry/deleteMinistry',
  async (id: string, { dispatch }) => {
    await ministryAPI.deleteOne(id);

    dispatch(fetchMinistries());

    return id;
  }
);

const ministrySlice = createSlice({
  name: 'ministry',
  initialState,
  reducers: {
    setCurrentMinistry(
      state,
      action: {
        payload: string;
        type: string;
      }
    ) {
      state.current = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMinistries.pending, (state) => {
        state.status = LOADING_STATES.LOADING;
      })
      .addCase(fetchMinistries.fulfilled, (state, action) => {
        state.status = LOADING_STATES.LOADED;
        state.list = action.payload;

        if (state.list.length > 0 && !state.current) {
          state.current = state.list[0].id;
        } else if (state.list.length === 0) {
          state.current = null;
        }
      })
      .addCase(fetchMinistries.rejected, (state, action) => {
        state.status = LOADING_STATES.IDLE;
        state.error = action.error.message || 'Failed to fetch list';
      })
      .addCase(createMinistry.fulfilled, (state, action) => {
        state.list.push(action.payload);
        state.current = action.payload.id;

        toast.success('Ministério criado!');
      })
      .addCase(updateMinistry.fulfilled, (state, action) => {
        const updatedMinistry = action.payload;

        state.list = state.list.map((ministry) =>
          ministry.id === updatedMinistry.id ? updatedMinistry : ministry
        );

        toast.success('Ministério atualizado!');
      })
      .addCase(deleteMinistry.fulfilled, (state, action) => {
        state.list = state.list.filter(
          (ministry) => ministry.id !== action.payload
        );

        if (state.current === action.payload) {
          state.current = state.list.length > 0 ? state.list[0].id : null;
        }

        toast.success('Ministério deletado!');
      });
  },
});

export const { setCurrentMinistry } = ministrySlice.actions;

export default ministrySlice.reducer;
