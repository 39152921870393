import { Skeleton } from '@/components/ui/skeleton';

export function UserSkeleton() {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center space-x-2 w-full mt-[2px] mb-[2px]">
        <Skeleton className="h-8 w-8 rounded-full" />

        <div className="space-y-[7px]">
          <Skeleton className="h-3 w-[140px]" />
          <Skeleton className="h-3 w-[160px]" />
        </div>
      </div>

      <Skeleton className="h-8 w-[130px]" />
    </div>
  );
}
