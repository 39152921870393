import React from 'react';
import { EmptyEvents } from '@/components/dashboard/EmptySection/EmptyEvents.component';
import { Card, CardContent } from '@/components/ui/card';

export const EventsSection: React.FC = () => {
  const events = [];

  return (
    <Card className="shadow-none overflow-hidden rounded-md">
      {Boolean(events.length) ? (
        <div>Events</div>
      ) : (
        <CardContent className="px-4 py-12 w-full flex flex-col justify-center items-center">
          <EmptyEvents />
        </CardContent>
      )}
    </Card>
  );
};
