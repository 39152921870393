import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { MinistryType } from '@/dtos/ministry/ministry.dto';
import { CornerDownRight, Check } from 'lucide-react';
import { DynamicIcon } from 'lucide-react/dynamic';

export const MinistryItem: React.FC<{
  ministry: MinistryType;
  isChild?: boolean;
  isActive?: boolean;
  onClick: () => void;
}> = ({ ministry, isChild = false, isActive = false, onClick }) => (
  <DropdownMenuItem
    key={ministry.id}
    onClick={onClick}
    className="w-full p-2 flex items-center justify-between"
  >
    <div className="flex items-center gap-2">
      {isChild && (
        <CornerDownRight className="ml-1 mr-1 text-muted-foreground !size-4" />
      )}
      <div className="flex size-6 items-center justify-center rounded-sm bg-gradient-to-tl from-teal-200/50 to-green-300/50">
        <DynamicIcon name={ministry.data.icon} className="size-4 shrink-0" />
      </div>
      {ministry.name}
    </div>
    {isActive && <Check />}
  </DropdownMenuItem>
);
