import React from 'react';
import { EventsSection } from './sections/EventsSection.component';
import { useSelector } from '@/hooks/redux.hook';
import { getCurrentMinistry } from '@/features/ministry/minsitry.selector';
import { DashboardHeader } from '@/components/dashboard/header/DashboardHeader.component';

export const Events = () => {
  const currentMinistry = useSelector(getCurrentMinistry);

  const breadcrumbItems = [
    { name: 'Ministério', link: '#' },
    { name: currentMinistry?.name || 'Ministério' },
    { name: 'Todos os eventos' },
  ];

  return (
    <div className="w-full h-full">
      <DashboardHeader breadcrumbs={breadcrumbItems} />
      <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
        <EventsSection />
      </div>
    </div>
  );
};
