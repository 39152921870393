import React from 'react';
import { EmptySection } from './EmptySection.component';
import { EmptySectionBackground } from './EmptySectionBackground.component';
import { MODALS } from '@/components/modals/modals.constants';
import { useModalAPI } from '@/components/modals/modals.hook';
import { TicketPlus } from 'lucide-react';

export const EmptyEvents = () => {
  const eventsModal = useModalAPI(MODALS.EVENT);

  return (
    <EmptySection
      title="Você ainda não tem nenhum evento"
      description={
        <>
          Crie e divulgue seu primeiro evento agora mesmo em{' '}
          <strong className="font-semibold">menos de 5 minutos</strong>.
        </>
      }
      primaryButton={{
        label: 'Criar evento',
        onClick: () => eventsModal.open(),
      }}
      secondaryButton={{
        label: 'Saiba mais',
        onClick: () => console.log('Saiba mais'),
      }}
    >
      <div className="flex justify-center items-center relative p-2 mb-14 mt-8 w-10 h-10">
        <EmptySectionBackground />
        <TicketPlus className="h-16 w-16 text-gray-600" />
      </div>
    </EmptySection>
  );
};
