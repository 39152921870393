import React from 'react';

import { House } from 'lucide-react';

export const Logo: React.FC = () => {
  return (
    <div className="flex justify-center gap-2 md:justify-start">
      <a href="/" className="flex items-center gap-1 font-medium">
        <div className="flex h-6 w-6 items-center justify-center text-primary">
          <House className="size-5 " />
        </div>
        <div>
          Ministry<strong>Hub</strong>
        </div>
      </a>
    </div>
  );
};
