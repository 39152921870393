/* eslint-disable @typescript-eslint/no-redeclare */
import { User as SupabaseUser } from '@supabase/supabase-js';
import { Enum } from '@/utils';
import type { ProfileType } from '@/dtos/auth/profile.dto';

export const SignInMethod = { GOOGLE: 'google', EMAIL: 'email' } as const;
export type SignInMethod = Enum<typeof SignInMethod>;

export const LoadingStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
} as const;
export type LoadingStatus = Enum<typeof LoadingStatus>;

export type User = SupabaseUser;

export interface AuthState {
  token: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  signInMethod: SignInMethod | null;

  user: User | null;
  profile: ProfileType | null;
}
