import React, { FC } from 'react';

import { MODALS } from '../modals.constants';
import { useModalAPI } from '../modals.hook';
import {
  NotebookPen,
  Calendar,
  ListCheck,
  Ticket,
  PinIcon,
  ClipboardList,
  Check,
} from 'lucide-react';

import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from '@/components/ui/sidebar';
import { Progress } from '@/components/ui/progress';
import { CardDescription, CardTitle } from '@/components/ui/card';
import { GeneralInfo } from './sections/GeneralInfo.component';

const data = {
  nav: [
    {
      name: 'Informações gerais',
      Icon: Calendar,
      Component: GeneralInfo,
      height: 70,
    },
    {
      name: 'Descrição',
      Icon: NotebookPen,
      Component: GeneralInfo,
      height: 60,
    },
    {
      name: 'Formulário customizado',
      Icon: ClipboardList,
      Component: GeneralInfo,
      height: 50,
    },
    { name: 'Local', Icon: PinIcon, Component: GeneralInfo, height: 85 },
    { name: 'Ingressos', Icon: Ticket, Component: GeneralInfo, height: 70 },
    { name: 'Publicar', Icon: ListCheck, Component: GeneralInfo, height: 67 },
  ],
};

export const EventModal: FC = () => {
  const { isOpen, close, open } = useModalAPI(MODALS.EVENT);
  const [active, setActive] = React.useState(0);
  const [done] = React.useState(new Set<number>());
  const { Component } = data.nav[active];

  const handleOpenChange = () => (isOpen ? close() : open());

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent
        className="overflow-hidden p-0 h-full md:max-w-[700px] lg:max-w-[800px]"
        style={{ height: `${data.nav[active].height}%` }}
      >
        <SidebarProvider className="items-start h-full">
          <Sidebar className="hidden md:flex border-none">
            <SidebarContent className="h-full">
              <DialogHeader className="p-4">
                <CardTitle>Novo evento</CardTitle>
                <CardDescription>
                  Adicione as informações mais relevantes e comece a divulgar
                  hoje mesmo!
                </CardDescription>
              </DialogHeader>
              <SidebarGroup>
                <SidebarGroupContent>
                  <SidebarMenu>
                    {data.nav.map(({ name, Icon }, index) => (
                      <SidebarMenuItem key={name}>
                        <SidebarMenuButton
                          className="cursor-pointer"
                          asChild
                          isActive={index === active}
                          onClick={() => setActive(index)}
                        >
                          <div className="flex justify-between">
                            <div className="flex items-center">
                              <Icon className="w-4 h-4 mr-2" />
                              <span>{name}</span>
                            </div>

                            {done.has(index) && (
                              <Check className="w-6 h-6 text-green-500" />
                            )}
                          </div>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    ))}
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>
            </SidebarContent>
            <SidebarFooter>
              <div className="pr-4 pb-2">
                <SidebarGroupLabel>Etapa {active + 1} de 6</SidebarGroupLabel>
                <Progress
                  className="h-1 mx-2"
                  value={((active + 1) / 6) * 100}
                />
              </div>
            </SidebarFooter>
          </Sidebar>
          <main className="flex w-full h-full flex-col bg-muted/50 p-2 md:pl-0">
            <div className="h-full rounded-xl bg-white">
              <div className="md:hidden pr-4 pb-4">
                <SidebarGroupLabel>Etapa 1 de 6</SidebarGroupLabel>
                <Progress className="h-1 mx-2" value={(1 / 6) * 100} />
              </div>

              <div className="md:mt-6">
                <Component />
              </div>
            </div>
          </main>
        </SidebarProvider>
      </DialogContent>
    </Dialog>
  );
};
