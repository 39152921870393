import { FC } from 'react';
import { ErrorMessage as NativeErrorMessage, ErrorMessageProps } from 'formik';
import clsx from 'clsx';

export const ErrorMessage: FC<
  {
    className?: string;
  } & ErrorMessageProps
> = ({ className, ...props }) => {
  return (
    <NativeErrorMessage
      component="p"
      className={clsx(
        'text-red-500 text-sm font-medium leading-none',
        className
      )}
      {...props}
    />
  );
};
