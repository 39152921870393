import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      position="bottom-center"
      className="flex justify-center"
      toastOptions={{ className: 'w-fit' }}
      {...props}
    />
  );
};

export { Toaster };
