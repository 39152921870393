// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import { logout } from '../features/auth/auth.slice'; // import the asyncThunk
import authReducer from '../features/auth/auth.slice';
import ministryReducer, {
  initialState,
} from '../features/ministry/ministry.slice';
import modalsReducer from '../components/modals/modals.slice';

const appReducer = combineReducers({
  auth: authReducer,
  ministry: ministryReducer,
  modals: modalsReducer,
});

export const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: any
) => {
  if (logout.fulfilled.match(action)) {
    return appReducer(
      {
        ...state,
        ministry: initialState,
      },
      action
    );
  }

  return appReducer(state, action);
};
