import React, { FC } from 'react';
import { EventModal } from './Event/Event.modal';
import { MinistryCreate } from './Ministry/MinistryCreate.modal';
import { MinistryEdit } from './Ministry/MinistryEdit.modal';
import { MinistryMembers } from './Ministry/MinistryMembers.modal';

export const PrivateModals: FC = () => {
  return (
    <>
      <EventModal />
      <MinistryCreate />
      <MinistryEdit />
      <MinistryMembers />
    </>
  );
};

export default PrivateModals;
