import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MODALS } from './modals.constants';
import { peekLastModal } from './modals.utils';

interface ModalState {
  closePreventedBy: MODALS | null;
  stack: MODALS[];
  states: Record<MODALS, unknown> | Record<PropertyKey, never>;
}

const initialState: ModalState = {
  stack: [],
  closePreventedBy: null,
  states: {},
};

interface OpenModalPayload {
  name: MODALS;
  modalState: {};
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      const { name, modalState } = action.payload;
      state.stack.push(name);
      state.states[name] = modalState;
    },
    closeModal: (state) => {
      const lastModal = peekLastModal(state.stack);
      if (!lastModal) return;

      if (state.closePreventedBy === lastModal) return;

      state.stack.pop();
      delete state.states[lastModal];
    },
    preventClose: (state, action: PayloadAction<MODALS>) => {
      state.closePreventedBy = action.payload;
    },
    allowClose: (state) => {
      state.closePreventedBy = null;
    },
  },
});

export const { openModal, closeModal, preventClose, allowClose } =
  modalsSlice.actions;
export default modalsSlice.reducer;
