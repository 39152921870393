import { NavigateWithQuery } from '@/components';
import { AppSidebar } from '@/components/dashboard/navbar/AppSidebar.component';
import PrivateModals from '@/components/modals';
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar';
import { Settings } from 'lucide-react';
import { Routes, Route } from 'react-router-dom';
import { Events } from './pages/events/Events.page';

export const Dashboard = () => {
  return (
    <div className="font-sans">
      <SidebarProvider>
        <AppSidebar />
        <SidebarInset>
          <Routes>
            <Route path="events" element={<Events />} />
            <Route path="settings" element={<Settings />} />

            <Route
              path="/"
              element={<NavigateWithQuery to="/dashboard/events" />}
            />
          </Routes>
        </SidebarInset>

        <PrivateModals />
      </SidebarProvider>
    </div>
  );
};
