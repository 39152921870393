import { RootState } from '@/app/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectAuthState = (state: RootState) => state.auth;

export const selectCurrentUser = createSelector(
  [selectAuthState],
  (authState) => authState.user
);

export const selectCurrentProfile = createSelector(
  [selectAuthState],
  (authState) => authState.profile
);
