import { RootState } from '@/app/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectMinistryState = (state: RootState) => state.ministry;

export const selectMinistryList = createSelector(
  [selectMinistryState],
  (ministryState) => ministryState.list
);

export const allParentMinistries = createSelector(
  [selectMinistryList],
  (ministries) => ministries.filter((ministry) => !ministry.parentID)
);

export const createGetOneMinistry = () =>
  createSelector([selectMinistryList], (ministries) => {
    return (args: { id: string }) =>
      ministries.find((ministry) => ministry.id === args.id) || null;
  });

export const getCurrentMinistry = createSelector(
  [selectMinistryState],
  (ministryState) =>
    ministryState.list.find(
      (ministry) => ministry.id === ministryState.current
    ) || {}
);

export const mapAllMinistriesByParentID = createSelector(
  [selectMinistryList],
  (ministries) =>
    ministries.reduce(
      (acc, ministry) => {
        if (!acc[ministry.parentID]) {
          acc[ministry.parentID] = [];
        }

        acc[ministry.parentID].push(ministry);

        return acc;
      },
      {} as Record<string, any[]>
    )
);
