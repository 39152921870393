import { enableMapSet } from 'immer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './root-reducer';

enableMapSet();

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: ['auth', 'ministry'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
    ignoredPaths: ['register', 'rehydrate'],
    serializableStateInvariant: false,
  },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
