import * as React from 'react';
import { Calendar, UserCheck } from 'lucide-react';

import { NavEvents } from '@/components/dashboard/navbar/NavEvents.component';
import { NavProjects } from '@/components/dashboard/navbar/NavAdmin.component';
import { Ministries } from '@/components/dashboard/navbar/Ministries.component';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from '@/components/ui/sidebar';

// This is sample data.
const data = {
  events: [
    {
      name: 'Retiro de Páscoa 2025',
      url: '#',
    },
    {
      name: 'Retiro de verão: Família de Deus: Qual é o meu lugar?',
      url: '#',
    },
  ],
  projects: [
    {
      name: 'Agenda',
      url: '#',
      icon: Calendar,
    },
    {
      name: 'Voluntários',
      url: '#',
      icon: UserCheck,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <Ministries />
      </SidebarHeader>
      <SidebarContent>
        <NavEvents projects={data.events} />
      </SidebarContent>
      <SidebarFooter>
        <NavProjects projects={data.projects} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
