import { Skeleton } from '@/components/ui/skeleton';

export function MinistrySkeleton() {
  return (
    <div className="flex items-center space-x-2 w-full mt-2 mb-2">
      <Skeleton className="h-8 w-8 ml-2 rounded-lg" />
      <div className="space-y-[5px]">
        <Skeleton className="h-3 w-[160px]" />
        <Skeleton className="h-3 w-[60px]" />
      </div>
    </div>
  );
}
