import React, { FC } from 'react';

import { useModalAPI } from '../../modals.hook';
import { MODALS } from '../../modals.constants';
import { Button } from '@/components/ui/button';
import { MinistryRawType } from '@/dtos/ministry/ministry.dto';

export const GeneralInfo: FC = () => {
  const { open: openMinistry } = useModalAPI<MinistryRawType>(
    MODALS.MINISTRY_CREATE
  );

  return (
    <Button onClick={() => openMinistry()} className="p-2">
      Criar ministério
    </Button>
  );
};
