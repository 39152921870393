import React from 'react';
import { AuthHero } from '../components/AuthHero.component';
import { BlurFade } from '@/components/visual';
import { ShineBorder } from '@/components/visual/ShineBorder.component';
import GmailInboxIllustration from '@/components/visual/GmailIllustration.component';
import { Logo } from '@/components/visual/Logo.component';
import { SignUpForm } from '@/components/auth/SignUpForm.component';
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

export const Signup: React.FC = () => {
  const [awaitingConfirmation, setAwaitingConfirmation] = React.useState(false);

  return (
    <div className="grid min-h-svh lg:grid-cols-2">
      <div className="relative hidden bg-muted lg:block">
        <AuthHero />
      </div>
      <div className="relative flex flex-col gap-4 p-4 md:p-10">
        <Logo />

        <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <BlurFade isVisible={awaitingConfirmation} delay={0.2}>
            <ShineBorder>
              <CardHeader className="flex flex-col w-full px-4 pb-3">
                <CardTitle>Verifique seu email</CardTitle>
                <CardDescription>
                  Você deverá receber um email com o link de confirmação em no
                  máximo 60 segundos.
                </CardDescription>
              </CardHeader>
              <CardContent className="w-full">
                <BlurFade isVisible={awaitingConfirmation} delay={0.3}>
                  <GmailInboxIllustration />
                </BlurFade>
              </CardContent>
            </ShineBorder>
          </BlurFade>
        </div>

        <div className="flex flex-1 items-center justify-center">
          <div className="w-full max-w-xs">
            <BlurFade isVisible={!awaitingConfirmation} delay={0.2}>
              <SignUpForm onSubmit={() => setAwaitingConfirmation(true)} />
            </BlurFade>
          </div>
        </div>
      </div>
    </div>
  );
};
