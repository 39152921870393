import React from 'react';
import { useDispatch, useSelector } from '@/hooks/redux.hook';
import { RootState } from '@/app/store';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import * as validations from '@/utils/validations.util';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LoadingStatus, SignInMethod } from '@/features/auth/auth.interface';
import { login, loginWithGoogle } from '@/features/auth/auth.slice';
import { GoogleLogo } from '../visual/GoogleLogo.component';
import { ErrorMessage } from '../ui/error-message';

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const { status, signInMethod } = useSelector(
    (state: RootState) => state.auth
  );

  const validationSchema = Yup.object({
    email: validations.email,
    password: validations.password,
  });

  const handleSubmit = (values: { email: string; password: string }) => {
    dispatch(login(values));
  };

  const handleGoogleLogin = () => {
    dispatch(loginWithGoogle());
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={cn('flex flex-col gap-6 max-w-md mx-auto')} noValidate>
          <div className="flex flex-col items-center gap-2 text-center">
            <h1 className="text-2xl font-bold">Seja bem-vindo</h1>
          </div>

          <Button
            type="button"
            variant="outline"
            className="w-full flex items-center justify-center"
            onClickCapture={handleGoogleLogin}
            loading={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.GOOGLE
            }
            disabled={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.GOOGLE
            }
          >
            <GoogleLogo />
            Entre com o Google
          </Button>

          {/* Separator */}
          <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:border-t after:border-border">
            <span className="relative z-10 bg-background px-2 text-muted-foreground">
              ou
            </span>
          </div>

          {/* Email Field */}
          <div className="grid gap-2">
            <Label htmlFor="email">Seu Email</Label>
            <Field name="email">
              {({ field, meta }: any) => (
                <Input
                  {...field}
                  id="email"
                  type="email"
                  placeholder="nome@email.com"
                  required
                  className={meta.touched && meta.error ? 'border-red-500' : ''}
                />
              )}
            </Field>
            <ErrorMessage name="email" />
          </div>

          {/* Password Field */}
          <div className="grid gap-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="password">Senha</Label>
              <a
                href="/"
                className="text-sm underline-offset-4 hover:underline"
              >
                Esqueci minha senha
              </a>
            </div>
            <Field name="password">
              {({ field, meta }: any) => (
                <Input
                  {...field}
                  id="password"
                  type="password"
                  placeholder="********"
                  required
                  className={meta.touched && meta.error ? 'border-red-500' : ''}
                />
              )}
            </Field>
            <ErrorMessage name="password" />
          </div>

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-full"
            disabled={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.EMAIL
            }
            loading={
              status === LoadingStatus.LOADING &&
              signInMethod === SignInMethod.EMAIL
            }
          >
            Entrar
          </Button>

          {/* Footer */}
          <div className="text-center text-sm">
            Não tem uma conta?{' '}
            <a href="/signup" className="underline underline-offset-4">
              Crie aqui
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};
